import brandIcon from '../Assets/brandIcon.svg';
import brandName from '../Assets/brandName.svg';
import brandNameDark from '../Assets/brand_name_dark.svg';

const DARK_THEME = {
  id: 2,
  name: 'Dark Theme',
  primary: {
    main: '#212022',
    backgroundColor: '#262626',
    contrastText: '#ffffff',
    textColor: '#ffffff',
    errorColor: 'red',
    gray: '#99A3AC',
    blue: '#82ADBB',
  },
  common: {
    white: '#ffffff',
    black: '#000000',
    gray: '#D3D3D3',
    gradient: ['#EDDDEB', '#A2A5F4', '#98B8EF'],
  },
  secondary: {
    main: '#768CFA',
    backgroundColor: 'white',
    contrastText: '#4db5c4',
    textColor: '#4db5c4',
    errorColor: 'red',
  },
  coinColor: '#000000',
  dollarColor: '#ffffff',
  header: 'dark',
  logoUrl: brandIcon,
  brandNameUrl: brandName,
  brandNameDarkUrl: brandName,
  aprColor: '#21272A',
  infoIcon: '#99A3AC',
};

const LIGHT_THEME = {
  id: 1,
  name: 'Light Theme',
  common: {
    white: '#ffffff',
    black: '#000000',
    gray: '#D3D3D3',
    gradient: ['#EDDDEB', '#A2A5F4', '#98B8EF'],
  },
  primary: {
    main: '#F4F8FB',
    backgroundColor: '#ffffff',
    contrastText: '#4db5c4',
    textColor: '#0A0B20',
    errorColor: 'red',
    gray: '#99A3AC',
    blue: '#D9F0F6',
  },
  secondary: {
    main: '#768CFA',
    backgroundColor: 'grey',
    contrastText: '#ffffff',
    textColor: '#ffffff',
    errorColor: 'red',
  },
  dollarColor: '#768CFA',
  coinColor: '#f4f8fb',
  header: 'light',
  logoUrl: brandIcon,
  brandNameUrl: brandName,
  brandNameDarkUrl: brandNameDark,
  infoIcon: '#99A3AC',
};

const DEFAULT_THEME = LIGHT_THEME;

const HEADER_TITLE = 'Dashboard';

export const APP_DATA = {
  DEFAULT_THEME,
  LIGHT_THEME,
  DARK_THEME,
  HEADER_TITLE,
};
