import { ACTION_TYPES } from '../../Configs/ActionTypes';

/**
 * Returns a state of AppData
 * @returns {AppDataState}
 * The reducers receive all AppData actions and return the appropriate state
 * @param {AppDataState} state .
 * @param {AppDataAction} action .
 */
const appDataReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_APP_DATA:
      return { ...state, appData: action.payload };
    case ACTION_TYPES.UPDATE_THEME:
      return {
        ...state,
        appData: { ...state.appData, theme: action.payload },
      };

    case ACTION_TYPES.SET_IS_MOBILE:
      return {
        ...state,
        appData: { ...state.appData, isMobile: action.payload },
      };
    default:
      return state;
  }
};

export default appDataReducer;
