import { ENV_CONSTANTS } from './Constants';

/**
 * Define the common constants along all the application.
 * @constant {string} APP_NAME .
 * @constant {string} FAVICON .
 * @constant {string} BRAND_LOGO .
 */
const { APP_NAME, FAVICON, BRAND_LOGO } = ENV_CONSTANTS;

const NOTIFICATION_AUTO_CLOSE_MS = 2500;

const BUTTON_TEXT = {
  GO_TO_HOME: 'Go To Home',
};

/**
 * Export all constants in one to deal with them by the name of COMMON.
 * @constant {Object} COMMON .
 */
// Export all constants in one to deal with them by the name of COMMON
export const COMMON = {
  APP_NAME,
  FAVICON,
  BRAND_LOGO,
  NOTIFICATION_AUTO_CLOSE_MS,
  BUTTON_TEXT,
};
