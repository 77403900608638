import brandIcon from '../Assets/brandIcon.svg';

import Partner1 from '../Assets/partners/partner1.png';
import Partner2 from '../Assets/partners/partner2.png';
import Partner3 from '../Assets/partners/partner3.png';
import Partner4 from '../Assets/partners/partner4.png';
import Partner5 from '../Assets/partners/partner5.png';
import Partner6 from '../Assets/partners/partner6.png';
import Partner7 from '../Assets/partners/partner7.png';
import Partner8 from '../Assets/partners/partner8.png';
import Partner9 from '../Assets/partners/partner9.png';

import buyTread1 from '../Assets/buyTrade/buyTrade1.png';
import buyTread3 from '../Assets/buyTrade/buyTrade3.png';
import buyTread4 from '../Assets/buyTrade/buyTrade4.png';
import buyTread5 from '../Assets/buyTrade/buyTrade5.png';
import buyTread6 from '../Assets/buyTrade/buyTrade6.png';
import buyTread7 from '../Assets/buyTrade/buyTrade7.png';
import buyTread8 from '../Assets/buyTrade/buyTrade8.png';
import buyTread9 from '../Assets/buyTrade/buyTrade9.png';
import buyTread10 from '../Assets/buyTrade/buyTrade10.png';
import buyTread11 from '../Assets/buyTrade/buyTrade11.png';

const PRODUCTION_ENV = 'PROD';
const DEVELOPMENT_ENV = 'DEV';
const CURRENT_ENV =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? DEVELOPMENT_ENV
    : PRODUCTION_ENV;

const BRAND_LOGO = process.env.REACT_APP_BRAND_LOGO || brandIcon;

const APP_NAME = process.env.REACT_APP_APP_NAME || 'Polytrade';

const FAVICON = process.env.REACT_APP_FAVICON || brandIcon;

const INFO_DETAILS = {
  TOTAL_LIQUIDITY: 'The total amount deposited in all available lender pools',
  INVOICE_FUNDED: 'Total invoice funded for the Polytrade protocol',
};

const TO_FIXED_DECIMALS = 2;
const DEFAULT_TRADE_AMOUNT = 1;

const API_SERVER_URL =
  process.env.REACT_APP_SERVER_URL || 'http://localhost:5000';

const API_V1_SERVER_URL =
  process.env.REACT_APP_V1_SERVER_URL || 'https://dev-api.polytrade.app';

const V1_URL = process.env.REACT_APP_V1_FE_URL || 'https://v1.polytrade.app/';
const V2_URL =
  process.env.REACT_APP_V2_FE_URL || 'https://lender.polytrade.app';

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || 'UA-233532269-1';
const GTM_ID = process.env.REACT_APP_GTM_ID || 'GTM-T65D5DH';

const MOBILE_BREAK_POINT = 720;

const GATE_LINK = 'https://www.gate.io/trade/TRADE_USDT';
const KUCOIN_LINK = 'https://www.kucoin.com/trade/TRADE-USDT';
const COIN_DCX_LINK = 'https://coindcx.com/trade/TRADEINR';
const MEXC_LINK = 'https://www.mexc.com/exchange/TRADE_USDT';
const UNISWAP_LINK =
  'https://v2.info.uniswap.org/token/0x6e5970dbd6fc7eb1f29c6d2edf2bc4c36124c0c1';
const PANCAKE_LINK =
  'https://pancakeswap.finance/swap?outputCurrency=0x6Ba7a8f9063c712C1c8CabC776B1dA7126805f3b';
const BIT_MART_LINK =
  'https://www.bitmart.com/trade/en?layout=basic&symbol=TRADE_USDT';
const BIT_FINEX_LINK = 'https://trading.bitfinex.com/t/TRADE:UST';
const QUICK_SWAP_LINK =
  'https://info.quickswap.exchange/#/token/0x692ac1e363ae34b6b489148152b12e2785a3d8d6';

const TSPICE_POLYTRADE = 'https://tspice.polytrade.app';

const USD = {
  NAME: 'USD',
  SYMBOL: '$',
};

const BUY_TREAD = [
  {
    id: 1,
    logo: Partner1,
    link: GATE_LINK,
  },
  {
    id: 2,
    logo: Partner2,
    link: KUCOIN_LINK,
  },
  {
    id: 3,
    logo: Partner3,
    link: BIT_FINEX_LINK,
  },
  {
    id: 4,
    logo: Partner4,
    link: COIN_DCX_LINK,
  },
  {
    id: 5,
    logo: Partner5,
    link: MEXC_LINK,
  },
  {
    id: 6,
    logo: Partner6,
    link: BIT_MART_LINK,
  },
  {
    id: 7,
    logo: Partner7,
    link: PANCAKE_LINK,
  },
  {
    id: 8,
    logo: Partner8,
    link: UNISWAP_LINK,
  },
  {
    id: 9,
    logo: Partner9,
    link: QUICK_SWAP_LINK,
  },
];
const PARTNERS = [
  {
    id: 1,
    logo: buyTread1,
  },
  {
    id: 3,
    logo: buyTread3,
  },
  {
    id: 4,
    logo: buyTread4,
  },
  {
    id: 5,
    logo: buyTread5,
  },
  {
    id: 6,
    logo: buyTread6,
  },
  {
    id: 7,
    logo: buyTread7,
  },
  {
    id: 8,
    logo: buyTread8,
  },
  {
    id: 9,
    logo: buyTread9,
  },
  {
    id: 10,
    logo: buyTread10,
  },
  {
    id: 11,
    logo: buyTread11,
  },
];

const TOTAL_SUPPLY = 100_000_000;

const CONSTANT_LIQUIDITY = 1_110_000.01;

const TRADE = {
  ID: 'TRADE',
  NAME: 'Polytrade',
  SYMBOL: 'TRADE',
  DECIMALS: 18,
  ICON: brandIcon, // Should be implemented as the rest
  IS_PRIMARY: false,
};

const INVOICE_FACTORING_URL = 'https://app.nine1.co.in/';

const IVY_INVOICE_FACTORING_URL = 'https://ivy.trade';

export const ENV_CONSTANTS = {
  PRODUCTION_ENV,
  DEVELOPMENT_ENV,
  CURRENT_ENV,
  BRAND_LOGO,
  APP_NAME,
  FAVICON,
  API_SERVER_URL,
  API_V1_SERVER_URL,
  TO_FIXED_DECIMALS,
  INFO_DETAILS,
  DEFAULT_TRADE_AMOUNT,
  GA_TRACKING_ID,
  GTM_ID,
  MOBILE_BREAK_POINT,
  USD,
  PARTNERS,
  BUY_TREAD,
  TRADE,
  V1_URL,
  V2_URL,
  TSPICE_POLYTRADE,
  TOTAL_SUPPLY,
  CONSTANT_LIQUIDITY,
  INVOICE_FACTORING_URL,
  IVY_INVOICE_FACTORING_URL,
};
