import { createContext, useReducer, useMemo } from 'react';
import { APP_DATA } from '../Configs/AppData';
import { COMMON } from '../Configs/Common';
import { ENV_CONSTANTS } from '../Configs/Constants';
import appDataReducer from '../Reducers/AppData/AppData';

/**
 * Default values that will be loaded once the application opened.
 * @constant {Theme} defaultTheme .
 */
let defaultTheme = APP_DATA.DEFAULT_THEME;

/**
 * Assign the default theme to the application based on whether it exists in the local storage or not.
 */
const localData = localStorage.getItem(COMMON.APP_NAME);
if (localData) {
  const { theme } = JSON.parse(localData);
  if (theme) {
    defaultTheme = theme;
  }
}

/**
 * Initial state will be dispatched.
 * @constant {AppDataState} appDataInitialState .
 */
const appDataInitialState = {
  appData: {
    theme: defaultTheme,
    activeNotification: null,
    isMobile: window.innerWidth <= ENV_CONSTANTS.MOBILE_BREAK_POINT,
  },
};

/**
 * Create and export the context that will wrap all the application.
 * @constant {React.Context} AppDataContext .
 */
export const AppDataContext = createContext({
  state: appDataInitialState,
  dispatch: () => null,
});

/**
 * Return a provider to manage the state of the application
 * @returns {JSX.Element}
 * Function to create a provider to wrap all the application
 * Export the provider that we will use in need of context props
 * @param {Node} children .
 */
export const AppDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appDataReducer, appDataInitialState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <AppDataContext.Provider value={value}>{children}</AppDataContext.Provider>
  );
};
