/**
 * Define all Action Types we need to dispatch in the application.
 * @constant {string} GET_APP_DATA .
 * @constant {string} WALLET_CONNECT .
 * @constant {string} WALLET_DISCONNECT .
 * @constant {string} WALLET_CHAIN_CHANGED .
 */
const GET_APP_DATA = 'GET_APP_DATA';

const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

const UPDATE_THEME = 'UPDATE_THEME';

const SET_IS_MOBILE = 'SET_IS_MOBILE';

const SET_INVOICE_FUNDED = 'SET_INVOICE_FUNDED';
const SET_LIQUIDITY = 'SET_LIQUIDITY';

/**
 * Export all Action Types that the application will use and dispatch it.
 * @constant {Object} ACTION_TYPES .
 */
export const ACTION_TYPES = {
  GET_APP_DATA,
  UPDATE_THEME,
  TOGGLE_SIDEBAR,
  SET_INVOICE_FUNDED,
  SET_LIQUIDITY,
  SET_IS_MOBILE,
};
